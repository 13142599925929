// custom typefaces
import '@fontsource/inter';
import "@fontsource/lexend";
import '@fontsource/montserrat';
import '@fontsource/roboto';
import "@fontsource/bree-serif"

// grab CSS
import "./src/assets/css/main.css"

import * as React from "react";
import { ApolloProvider } from "@apollo/client";

import { client } from "./src/lib/apolloClient"
import { AuthProvider } from "./src/hooks/useAuth";
// import "./src/styles/global.css";

export const wrapRootElement = ({ element }) => (
  <ApolloProvider client={client}>
    <AuthProvider>{element}</AuthProvider>
  </ApolloProvider>
);
